import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import styled from "styled-components"
import ButtonWithSVG from "./ButtonWithSVG.js"
import Smartphone from "../../data/icons/smartphone.svg"
import Monitor from "../../data/icons/monitor.svg"


const Container = styled.div`
position:relative;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
-ms-flex-direction:column;
        flex-direction:column;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
width:100%;
margin-bottom:100px;

h2{
    text-align:center;
    position:relative;
    font-size:1.8em;
    line-height:1.2em;
    margin:50px 20px;

    &:after{
        content: '';
        position: absolute;
        left: 30%; 
        bottom: -5px;
        height: 3px;
        width:40%;
        background-color: var(--tmain);
        border-radius:200px;
    }
}
`
const ImageWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;

height:420px;

@media (min-width:603px) {
    height:560px;
}
@media (min-width:993px) {
    height:720px;
}

width:80%;
`

const GatsbyImageMobile = styled(GatsbyImage) `
display:${props => (props.ismobile==1)?("block"):("none")} !important;
overflow:visible !important;
max-width:80%;
width:240px;

@media(min-width:603px) {
    width:320px;
}
@media(min-width:993px) {
    width:380px;
}

& img {
    filter:drop-shadow(0px 0px 10px rgba(0,0,0,.3));
}
`
const GatsbyImageDesktop = styled(GatsbyImage)`
display:${props => (props.ismobile==1)?("none"):("block")} !important;

max-width:1080px;
overflow:visible !important;

& img {
    filter:drop-shadow(0px 0px 10px rgba(0,0,0,.3));
}
`

const SubImageWrapper = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:horizontal;
-webkit-box-direction:normal;
-ms-flex-flow:row wrap;
        flex-flow:row wrap;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
-webkit-box-pack:space-evenly;
-ms-flex-pack:space-evenly;
        justify-content:space-evenly;
width:80%;
`
const SubImage = styled.div`
width:auto;
max-width:1440px;
margin:20px 20px;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-pack:center;
-ms-flex-pack:center;
        justify-content:center;
`
const ToggleWrapper = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:horizontal;
-webkit-box-direction:normal;
-ms-flex-direction:row;
        flex-direction:row;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
& svg {
    margin-left:0.8em;
    width:4em;
    height:4em;
}
`

const Toggle = styled.span`
-webkit-transition: 0.3s ease;
-o-transition: 0.3s ease;
transition: 0.3s ease;
position:relative;
border-radius:1.5em;
background-color:#ddd;
height:1.5em;
width:4.5em;
& div {
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
    position:relative;
    margin-left: ${props => (props.ismobile==1)?("0%"):("67%")};
    height:1.5em;
    width:1.5em;
    border-radius:1.5em;
    background-color:var(--tmain);

}
&:hover {
    background-color:#bbb;
}
`


class ExampleWebsite extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            isMobile: 1
        }
    }

    componentDidMount(){
        if(window.innerWidth>=993){
            this.setState({isMobile: 0})
        }
    }

    toggleView(){
        if(this.state.isMobile==1) {
            this.setState({
                isMobile: 0
            })
        }
        else if(this.state.isMobile==0) {
            this.setState({
                isMobile: 1
            })
        }
    }

    render(){
    return(
        <Container>
            <h2>{this.props.data.title}</h2>
            <ImageWrapper>
                <GatsbyImageDesktop ismobile={this.state.isMobile} image={getImage(this.props.data.desktopImage.src)} alt={this.props.data.desktopImage.alt}/>
                <GatsbyImageMobile ismobile={this.state.isMobile} image={getImage(this.props.data.mobileImage.src)} alt={this.props.data.mobileImage.alt}/>
            </ImageWrapper>
            
            <SubImageWrapper>
                <SubImage>
                    <ToggleWrapper  >
                        <Smartphone/>
                        <Toggle tabIndex={0} onKeyDown={this.toggleView.bind(this)} onClick={this.toggleView.bind(this)}  ismobile={this.state.isMobile}>
                            <div></div>
                        </Toggle>
                        <Monitor/>
                    </ToggleWrapper>
                    
                </SubImage>
                <ButtonWithSVG href={this.props.data.link} width={"300px"} color={"var(--tsec)"} backgroundcolor={"var(--tmain)"} >Seite anzeigen</ButtonWithSVG>
            </SubImageWrapper>
            
        </Container>
    )
    }
}
export default ExampleWebsite;