import React from "react"
import styled from "styled-components"

const Container = styled.div`
position:relative;
top:0px;
text-align:center;
width:600px;
max-width:90%;
margin:auto;

@media (min-width:993px) {
    top:-50px;
}
`

export default function Disclaimer(props){


    return(
        <Container>
            <p>{props.children}</p>
        </Container>
    )
}