import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Arrow from "../../data/icons/arrow-right.svg"

const Container = styled(Link)`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:horizontal;
-webkit-box-direction:normal;
-ms-flex-direction:row;
        flex-direction:row;
padding:0.4em 1em 0.1em 1em;
text-decoration:none;
height:2.6em;
border-radius:50px;
font-size:1.0em;
width: ${props => props.width};
background-color:${props => props.backgroundcolor};

-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
border: 2px solid ${props => props.backgroundcolor};

-webkit-transition: all 0.15s linear;
-o-transition: all 0.15s linear;
transition: all 0.15s linear;


& p {
    color:${props => props.color};
    -ms-flex-preferred-size:67%;
        flex-basis:67%;
    text-align:center;
    margin:0;
    padding:0;
}
& svg {
    -ms-flex-preferred-size:33%;
        flex-basis:33%;
    height:1.5em;
    margin-bottom:0.2em;
    
    
}
&:hover{
    background-color:white;
    & svg {
        & path{
            fill:var(--tmain);
        }
    }
    & p {
        color:${props => props.backgroundcolor};
    }
}
`

export default function ButtonWithSVG(props){

    let color
    color = (props.color==null) ? (color="black"):(color=props.color)
    let backgroundcolor 
    backgroundcolor = (props.backgroundcolor==null) ? (backgroundcolor="var(--tgrey)"):(backgroundcolor=props.backgroundcolor)
    let href
    href = (props.href==null)?(href=""):(href=props.href)
    let width
    width = (props.width==null)?(width="auto"):(width=props.width)

    return(
        <Container to={href} color={color} backgroundcolor={backgroundcolor} width={width}>
            <p>{props.children}</p>
            <Arrow/>
        </Container>
    )
}