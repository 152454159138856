import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import ExampleWebsite from "../components/ExampleWebsite.js"
import BottomBanner from "../components/BottomBanner.js"
import ContactTopBanner from "../components/ContactTopBanner"
import Disclaimer from "../components/Disclaimer.js"

export default function Websites({data}){
    const websites = data.allWebsitesJson.nodes[0]
    const bottomBanner = websites.BottomBanner
    const global = data.allGlobalJson.nodes[0]

    return(
      <div>
        <Helmet>
          <meta charSet="utf-8"/>
          <title>Websites</title>
          <html lang="de"/>
        </Helmet>
        <Layout>
        <ContactTopBanner/>
        <Disclaimer>{websites.disclaimer}</Disclaimer>
          {
            websites.examples.map((item, i) => <ExampleWebsite key={i} data={item}>
                
            </ExampleWebsite> )
          }
          <BottomBanner button={bottomBanner.button} text={global.Slogan3}/>
        </Layout>
      </div>
    )
}

export const query = graphql`
{
  allWebsitesJson {
    nodes {
      title
      disclaimer
      examples {
        title
        desktopImage {
          src {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          alt
        }
        mobileImage {
          src {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          alt
        }
        link
      }
      BottomBanner {
        button {
          href
          text
        }
      }
    }
  }
  allGlobalJson {
    nodes {
      Slogan1
      Slogan2
      Slogan3
    }
  }
}`